body {
  margin: 0;
  /* alttaki sonradan */
  padding: 0;
  width: 100%;

  /* app.css */
  /* margin: 0; */
  /* padding: 0; */
  margin-top: 25px;
  /* width: 100%; */
  height: 90vh;

  /* home.css */
  /* margin: 25px; */
  /* width: 100%; */
  /* height: 90vh; */
  color: #6aa8bf;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
