* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* body {
  height: 100vh;
  background-color: #ffff;
} */

li {
  list-style: none;
}

a {
  text-decoration: none;
  font-size: 1rem;
  color: #6aa8bf;
}

a:hover {
  color: rgb(80, 109, 170);
}

header {
  position: relative;
  padding: 0 2rem;
}

.navbar {
  width: 100%;
  /* orjinali 60px */
  height: 20px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
}

.navbar .logo a {
  font-size: 3rem;
  font-weight: bold;
  color: #6aa8bf;
}

.navbar .links {
  display: flex;
  gap: 2rem;
}

.navbar .toggle_btn {
  color: #6aa8bf;
  font-size: 1.5rem;
  cursor: pointer;
  display: none;
}

.bize_ulasin {
  background-color: #6aa8bf;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  outline: none;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
  transition: scale 0.2 ease;
}
.bize_ulasin:hover {
  scale: 1.05;
  color: #fff;
}

.bize_ulasin:active {
  scale: 0.95;
}

/* dropdown menu */

.dropdown_menu {
  color: white;
  display: none;
  position: absolute;
  right: 2rem;
  top: 60px;
  height: 0;
  width: 300px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
  z-index: 99;
  border-radius: 10px;
  overflow: hidden;
  transition: height 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.dropdown_menu.open {
  height: 240px;
}
.dropdown_menu a {
  color: black;
  text-shadow: 1px 1px 0.5px lightgrey;
}
.dropdown_menu li {
  padding: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown_menu .bize_ulasin {
  width: 100%;
  display: flex;
  justify-content: center;
}
.nav_middle {
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.mail,
.phone {
  display: flex;
  align-items: center;
}

/* bu classın içindeki her a nın yanına 5px padding yap */
.mail > a {
  padding-left: 5px;
}
@media (max-width: 576px) {
  .mail {
    display: none;
  }
  .phone {
    font-size: 0.7rem;
  }
}

/* logo - 60px dediği navbarın yüksekliği*/
/* section#logo {
  height: calc(100vh-60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: black;
}
#logo h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
} */

/* responsive design*/

/* bize ulaşın ve linkleri kaldırıyor mobilde */
@media (max-width: 992px) {
  .navbar .links,
  .navbar .bize_ulasin {
    display: none;
  }
  .navbar .toggle_btn {
    display: block;
  }
  .dropdown_menu {
    display: block;
  }
}

@media (max-width: 576px) {
  .dropdown_menu {
    left: 2rem;
    width: unset;
  }
}
