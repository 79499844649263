/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

.topbody {
  /* sayfa yüksekliği */
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  /* background-color: white; */
}
/* grid test */

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
/* ------------------------------------------------------ */
/* ellerin kayması için ekledim komple silebilirsin çizgiler arasını sorun çıkarsa */
/* sol_resim ve .sag_resim dede .kaymacontainer ekledim jsx içerisinde sadece o divleri silebilirsin en üstte ve en altta */
.kaymacontainer {
  /* display: flex;
  justify-content: flex-end; */
  position: relative;
  width: 100vw;
  height: 100vh;
  /* align-items: center; */
  overflow: hidden;
}
.sag_resim,
.sol_resim {
  position: absolute;
  top: 40%;
  left: 20%;
  transform: translateY(-50%);
  animation-duration: 1s;
  animation-fill-mode: both;
}

.sag_resim {
  animation-name: slide-in-left;
}

.sol_resim {
  animation-name: slide-in-right;
}

@keyframes slide-in-right {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

/* ------------------------------------------------------ */
@media (max-width: 576px) {
  .sol_resim,
  .sag_resim {
    display: none;
  }
}

/* topslider firma logoları boyut */
header img {
  max-width: 180px;
}
.accordion {
  max-width: 530px;
  width: 100%;
  /* background-color: yellow; */
  margin: 0 15px;
  padding: 15px;
  border-radius: 8px;

  /* box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); */
}

.accordion .accordion-content {
  margin: 20px 0;
  border-radius: 4px;
  /* background-color: #fff7f0; */
  /* BORDER BOYUT */
  /* border: 1px solid #6aa8bf; */
  overflow: hidden;
}

/* p Link {
  color: black;
} */

/* alt kırılımları childları numaralandırarak onlara özel renklendirme vs */
.accordion-content:nth-child(2) {
  /* background-color: #f0faff; */
  /* border-color: black; */
}
.accordion-content:nth-child(3) {
  /* background-color: #fff0f3;
  border-color: #ffccd6; */
}
.accordion-content:nth-child(4) {
  /* background-color: #f0f0ff;
  border-color: #ccccff; */
}

.accordion-content.open {
  padding-bottom: 10px;
}

.accordion-content header {
  display: flex;
  min-height: 50px;
  /* background-color: red; */
  padding: 0 15px;
  cursor: pointer;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: center;
  transition: all 0.2s linear;
}

.accordion-content.open header {
  min-height: 35px;
}

.accordion-content header .title {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}
.accordion-content header .icon {
  font-size: 15px;
  color: #333;
}
.accordion-content .description {
  height: 0;
  font-size: 15px;
  color: #333;
  font-weight: 700;
  width: 350px;
  padding: 0 15px;
  transition: all 0.2s linear;
  text-align: justify;
}

/* sonradan alttakini ekledim 06.11.2023 */
.accordion-content .detay {
  text-align: center;
  font-weight: 1000;
  font-size: 20px;
}
