/* * {
  burası navbar ile çakışıyor
  box-sizing: border-box;
  margin: 20px;
  padding: 0;

} */
.Elogo {
  margin-top: 20px;
}
