#root {
  /* max-width: 1280px; */
  /* margin: 0 auto; */
  /* padding: 2rem; */
  /* text-align: center; */
}

/* max-width: 1280px; */
/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box; n
} */

/* tekrar bak */
/* sayfa düzeni */
/* body {
  margin: 25px;
  width: 100%;
  height: 90vh;
  color: #6aa8bf;
} */

.firmabaslik {
  left: 0;
  font-weight: 1000;

  height: 100%;
  margin-bottom: 4px;
  margin-top: 4px;
}

.card {
  /* card boyutu */
  width: 300px;
  height: 200px;
  border-radius: 20px;
  overflow: hidden;
  /* alttaki silinebilir */
  position: relative;
  /* cursor: pointer; */
  left: 10px;

  /* margin: auto 1rem; */
  margin: 4px 0 4px 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* text-align: center; */
  font-family: arial;
}
.carousel-katman {
  margin-bottom: 4px;
}
@media (max-width: 576px) {
  .card {
    margin-left: 47px;
  }
}
.card-list-detail {
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
}
.product--image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.card-body {
  width: 100%;
  height: 100%;
  top: 0;
  right: -100%;

  position: absolute;
  /* transparent effecti aşşağıdaki renk kutucuğuna tıklayıp soldakini aşşağı yukarı indirince */
  background: rgba(245, 245, 245, 0.767);

  backdrop-filter: blur(5px);
  border-radius: 15px;
  /* hover text rengi */
  /* color: #fff; */
  color: black;

  /* cardın içinde kenar boşlukları */
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  transition: 1s;
}
.card:hover .card-body {
  right: 0;
}
.card-title {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 800;
}
.card-info {
  font-size: 16px;
  /* satırlar arası boşluk */
  line-height: 20px;
  /* başlıkla desc arası boşluk*/
  margin: 10px 0;
  /* card font kalınlığı */
  font-weight: 650;
}
