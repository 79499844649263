#root {
  /* max-width: 100%; */
  /* width: 100%; */

  /* margin: 0 auto;
  
  padding: 2rem; */
  /* text-align: center; */
}

/* max-width: 1280px; */
/* kaldırdım * olanı */
/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box; 
} */

/* kaldırdım body olanı */
/* alttaki 2si sonradan */
/* sayfa düzeni */
/* body {
  margin: 0;
  padding: 0;
  margin: 25px;
  width: 100%;
  height: 90vh;
} */

/* .firmabaslik {
  left: 0;
  font-weight: 1000;
} */

.card {
  /* card boyutu */
  width: 200px;
  height: 200px;
  border-radius: 20px;
  overflow: hidden;
  /* alttaki silinebilir */
  position: relative;
  cursor: pointer;
  left: 10px;

  /* margin: auto 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  text-align: center; */
  font-family: arial;
}
.card-list-detail {
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
}
.product--image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.card-body {
  width: 100%;
  height: 100%;
  top: 0;
  right: -100%;

  position: absolute;
  /* transparent effecti aşşağıdaki renk kutucuğuna tıklayıp soldakini aşşağı yukarı indirince */
  background: rgba(245, 245, 245, 0.247);
  backdrop-filter: blur(5px);
  border-radius: 15px;
  /* hover text rengi */
  color: #fff;
  /* cardın içinde kenar boşlukları */
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  transition: 1s;
}
.card:hover .card-body {
  right: 0;
}
.card-title {
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 1000;
}
.card-info {
  font-size: 16px;
  /* satırlar arası boşluk */
  line-height: 20px;
  /* başlıkla desc arası boşluk*/
  margin: 20px 0;
  font-weight: 300;
}
